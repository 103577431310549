import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { UpOutlined } from '@ant-design/icons';
import { IconFont } from '../../../component/icon-font';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
const { Button: DropdownButton } = Dropdown;
const { Item: MenuItem } = Menu;
interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick, onEndClick, isHost } = props;

  // return isHost ? (
  //   <DropdownButton
  //     style={{ marginLeft: '19px' }}
  //     size="large"
  //     menu={getAntdDropdownMenu([getAntdItem('End session', 'end')], onEndClick)}
  //     trigger={['click']}
  //     type="primary"
  //     onClick={onLeaveClick}
  //     placement="topRight"
  //   >
  //     <IconFont type="icon-leave" />
  //   </DropdownButton>
  // ) : (
  return (
    <Button
      className='vc-button'
      icon={<IconFont type="icon-leave" />}
      // eslint-disable-next-line react/jsx-boolean-value
      ghost={false}
      type="primary"
      shape="circle"
      size="large"
      onClick={onLeaveClick}
    />
  );
};

export { LeaveButton };
