import ZoomVideo from "@zoom/videosdk";
import { useEffect, useRef } from "react";
import { useZoomLimit } from "../../../component/LimitContext";

export const Preview = (props: { isBlur: boolean, isStarted: boolean }) => {
  const limit = useZoomLimit();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Video được khởi tạo bằng Zoom Video SDK
  let localVideo = ZoomVideo.createLocalVideoTrack();
  let localCanvas = ZoomVideo.createLocalVideoTrack();

  useEffect(() => {
    async function init() {
      if (canvasRef.current && videoRef.current) {
        await localCanvas.start(canvasRef.current, { imageUrl: "blur" });
        await localVideo.start(videoRef.current);
      }
    }

    async function leave() {
      await localCanvas.stop();
      await localVideo.stop();
    }
    limit(() => init());
    return () => {
      limit(() => leave());
    };
  }, []);

  return (
    <div className='flex justify-end mt-2'>
      <div className='flex-col'>
        <div>
          <video
            muted={true}
            ref={videoRef}
            className={`${props.isBlur || !props.isStarted ? "hidden" : ""} w-[150px] h-[75px]`}
          />

          <canvas
            ref={canvasRef}
            className={`${!props.isBlur || !props.isStarted ? "hidden" : ""} w-[150px] h-[75px]`}
          />
        </div>
      </div>
    </div>
  );
};
