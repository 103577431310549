import React from 'react';

interface UserAvatarProps {
  name?: string,
  positionX?: number,
  positionY?: number,
  avatarImage?: string,
  avatarWidth?: number,
  avatarHeight?: number,
}

function UserAvatar({ name, positionX, positionY, avatarImage, avatarWidth, avatarHeight }: UserAvatarProps) {

  //done
  return (
    <div
      data-name={`${name}`}
      className={`absolute rounded-full border-[#43640d] transition-all duration-200 
  before:content-[attr(data-name)] before:absolute before:bottom-[-60px] 
  before:left-1/2 before:text-[black] before:whitespace-nowrap before:bg-[#E3FDFD] 
  before:rounded-md before:transform before:-translate-x-1/2 before:text-center before:px-2`}
      style={{
        transform: `translate(${positionX}px, ${positionY}px)`,
        backgroundImage: `${avatarImage}`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderWidth: '8px',
        backgroundPosition: 'left top',
        fontSize: `${24}px`,
        height: `${avatarHeight}px`,
        width: `${avatarWidth}px`
      }}
    >
    </div>
  );
}

export default UserAvatar;