import { Alert, Button, Dropdown, Input, message, Modal, notification, Space } from 'antd';
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  AppstoreAddOutlined,
  DownOutlined,
  UserOutlined,
  AudioMutedOutlined,
  AudioOutlined,
  VideoCameraOutlined,
  VideoCameraFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  ProjectOutlined,
  LockOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import {
  avatarSize,
  distance,
  privateZonePositions,
  START_ZONE,
  talkSize,
  mapWidth,
  mapHeight,
  stepDistance
} from 'server/src/mapConst';
import { io, Socket } from 'socket.io-client';
import {
  ClientToServerEventsOfficeService,
  MapType,
  ServerToClientEventsOfficeService,
  User
} from 'server/src/types/office-service-type';
import MapPNG from '../../component/svgs/office/map.png';
import People from '../../component/svgs/office/people.png';
import { stopDoubleClickPropagation } from '../../utils/stopDoubleClickPropagation';
import UsiApp from './components/UsiApp';
import useMapInteractions from './hooks/useMapInteractions';
import PrivateZone from './PrivateZone';
import TalkPoint from './talkPoint';
import UserAvatar from './userAvatar';
import { isInStartZone } from './utils/isInStartZone';
import VideoTalkPoint from './VideoTalkPoint';
import { PrivateZoneType } from '../../index-types';
import PrivateZoneActive from './PrivateZoneActive';
import { useMoveHanderLimit } from '../../component/LimitContext';
import type { MenuProps } from 'antd';
import { CameraIcon } from './components/CameraIcon';
import { Preview } from './components/Preview';

const notice = notification['warning'];
const error = notification['error'];
const success = notification['success'];
const info = notification['info'];

const accessToken = new URLSearchParams(window.location.search).get('accessToken');

function MapOffice() {
  const [map, setMap] = useState<MapType>();
  const [mapSize, setMapSize] = useState({
    width: mapWidth,
    height: mapHeight
  });
  const [appearZoom, setAppearZoom] = useState<MapType['talkPoints'][0] | undefined>(undefined);
  const [isAppearUsiApp, setIsAppearUsiApp] = useState(false);
  const clientRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const socket = useRef<Socket<ServerToClientEventsOfficeService, ClientToServerEventsOfficeService>>();
  const [USIURL, setUSIURL] = useState<string>('');
  const [isBlur, setIsBlur] = useState(false);
  const [isStartVideo, setIsStartVideo] = useState(true);
  const [isStartAudio, setIsStartAudio] = useState(false);
  const isInRestZone = useRef(false);
  const [currentUserRoot, setCurrentUserRoot] = useState<User>(jwtDecode(accessToken ?? ''));
  const [privateZoneActive, setPrivateZoneActive] = useState<PrivateZoneType>();
  const [zonesOtherUserIn, setZonesOtherUserIn] = useState<Set<number>>();
  const [visibleSetting, setVisibleSetting] = useState(false);
  const privateZonePassword = useRef<string>();
  const EVKey = useRef<string>();

  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUpOrLeave,
    handleWheel,
    isDragging,
    handleZoom,
    scale,
    pointX,
    pointY
  } = useMapInteractions(clientRef, mapRef, mapSize.width, mapSize.height);

  // Hàm focus bàn phím trở về component hiện tại
  const handleClientFocus = () => {
    clientRef.current?.focus();
  };

  const handleReloadExist = (message: string) => {
    notice({
      closeIcon: <></>,
      message: '警告',
      description: message,
      btn: (
        <>
          <Button
            type="link"
            size="small"
            className="p-1"
            onClick={() => {
              window.location.reload();
            }}
          >
            リロード
          </Button>
        </>
      ),
      duration: 0,
      // onClose: () => notification.close(messageContent),
      placement: 'topRight'
    });
  };

  const handleCloseExist = (message: string) => {
    notice({
      closeIcon: <>✕</>,
      message: '警告', //Warning
      description: message,
      duration: 0
    });
  };

  const handleAppearUsiApp = () => {
    setIsAppearUsiApp((prev) => !prev);
  };

  const handleIsBlur = () => {
    setIsBlur((prev) => !prev);
  };

  const handleIsStartVideo = () => {
    // setIsStartVideo(prev => !prev);
    info({
      message: '現在のアプリではカメラをオフにすることができません。ご了承くださいませ。'
    });
  };

  const handleIsStartAudio = () => {
    setIsStartAudio((prev) => !prev);
  };

  useEffect(() => {
    //socket io định nghĩa
    if (!socket.current) {
      const socketTmp: Socket<ServerToClientEventsOfficeService, ClientToServerEventsOfficeService> = io(undefined, {
        reconnectionDelayMax: 10000,
        auth: {
          token: accessToken
        }
      });

      socket.current = socketTmp;
    }

    socket.current.emit('getEVKey', undefined, (key) => {
      EVKey.current = key;
    });

    socket.current.on('mapUpdate', async (data) => {
      setMap(data);
      //Xac dinh nguoi dung hien tai
      const currentUser = data.users.find((user) => user.userId === currentUserRoot.id);

      if (currentUser) {
        //kiểm tra user có di chuyển vào private zone không
        const userInPrivateZone = isUserInPrivateZone(
          currentUser.x + avatarSize / 2,
          currentUser.y + avatarSize / 2,
          data.privateZones
        );
        setPrivateZoneActive(userInPrivateZone);

        // danh sách các private zone có user ở trong
        const zonesOtherUserIn: Set<number> = new Set();

        data.users.map((user) => {
          const zone = isUserInPrivateZone(user.x + avatarSize / 2, user.y + avatarSize / 2, data.privateZones);
          if (zone) {
            zonesOtherUserIn.add(zone.id);
          }
        });

        // Check bao nhieu nguoi trong private room
        let usersInZone = 0;

        if (userInPrivateZone) {
          // Đếm số lượng người trong private zone
          usersInZone = data.users.filter((user) =>
            isUserInPrivateZone(user.x + avatarSize / 2, user.y + avatarSize / 2, [userInPrivateZone])
          ).length;
        }

        if (userInPrivateZone) zonesOtherUserIn.delete(userInPrivateZone.id);
        setZonesOtherUserIn(zonesOtherUserIn);

        // nếu trong privateZone thì lấy signature setAppearZoom r return luôn
        if (userInPrivateZone && usersInZone >= 2) {
          const signature = await new Promise<string>((resolve, reject) => {
            socket.current?.emit(
              'getPrivateZoneSignature',
              undefined,
              (result) => {
                if (result) {
                  resolve(result);
                } else {
                  console.error('getPrivateZoneSignature fail');
                }
              }
            );
          });

          if (EVKey.current !== '') {
            setAppearZoom({
              ...userInPrivateZone,
              signature: signature,
              id: Number(Number(EVKey.current + '' + userInPrivateZone.id))
            });
          } else {
            console.error('EVKey is undefined');
          }
          return;
        }

        if (isInStartZone(currentUser)) {
          setAppearZoom(undefined);
          return;
        }

        //Tinh toan nguoi dung hien tai co gan talkPoint nao khong
        const nearTalkPoint = data.talkPoints.find((talkPoint) => {
          const distanceTalkPoint = Math.sqrt(
            Math.pow(talkPoint.x + talkSize / 2 - (currentUser.x + avatarSize / 2), 2) +
              Math.pow(talkPoint.y + talkSize / 2 - (currentUser.y + avatarSize / 2), 2)
          );
          const nearDistanceThreshold = distance / 2;
          return distanceTalkPoint < nearDistanceThreshold;
        });

        //Neu co se tra ve true va hien thi man hinh Zoom Video SDK
        if (nearTalkPoint) {
          setAppearZoom({ ...nearTalkPoint });
        } else {
          setAppearZoom(undefined);
        }
      } else {
        setAppearZoom(undefined);
      }
    });

    socket.current.on('anotherConnecting', handleReloadExist);

    socket.current.emit('getUSIUrl', undefined, (url) => {
      setUSIURL(url);
    });

    return () => {
      socket.current?.off('mapUpdate');
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', moveUserWithKeys);
    return () => {
      window.removeEventListener('keydown', moveUserWithKeys);
    };
  }, [map, currentUserRoot]);

  const limit = useMoveHanderLimit();

  const handleMapClick = (e: React.MouseEvent) => {
    // Lấy bounding rectangle của phần tử
    const rect = mapRef.current!.getBoundingClientRect();

    // Tính toán vị trí click tương đối so với phần tử
    const xClick: number = e.clientX - rect.left;
    const yClick: number = e.clientY - rect.top;

    // nếu click ra ngoài màn hình
    if (xClick < 0 || xClick > mapSize.width * scale || yClick < 0 || yClick > mapSize.height * scale) return;
    handleUserMove(xClick, yClick, 'click');
  };

  const moveUserWithKeys = (event: KeyboardEvent) => {
    const currentUser = map?.users.find((user) => user.userId === currentUserRoot.id);
    if (!currentUser) return;

    let positionX = currentUser.x * scale;
    let positionY = currentUser.y * scale;

    switch (event.key) {
      case 'w': // Lên
      case 'ArrowUp':
        positionY -= stepDistance;
        break;
      case 's': // Xuống
      case 'ArrowDown':
        positionY += stepDistance;
        break;
      case 'a': // Trái
      case 'ArrowLeft':
        positionX -= stepDistance;
        break;
      case 'd': // Phải`
      case 'ArrowRight':
        positionX += stepDistance;
        break;
      default:
        return;
    }

    if (positionX < 0 || positionX > mapSize.width * scale || positionY < 0 || positionY > mapSize.height * scale)
      return;

    if (limit.activeCount !== 0) return;
    limit(() => handleUserMove(positionX, positionY, 'key'));
  };

  const handleUserMove = async (xClick: number, yClick: number, type: 'click' | 'key') => {
    if (clientRef.current && mapRef.current) {
      const xClickMapScale = xClick / scale;
      const yClickMapScale = yClick / scale;

      const x = xClickMapScale - (type === 'click' ? avatarSize / 2 : 0);
      let y = yClickMapScale - (type === 'click' ? avatarSize / 2 : 0);

      if (!map) return;

      // //kiểm tra user có di chuyển vào private zone không
      const userInPrivateZone = isUserInPrivateZone(xClick, yClick, map.privateZones);

      let password: string | undefined = undefined;
      // nếu zone đã có mật khẩu thì mở modal password
      if (map.privateZones?.find((zone) => zone.id === userInPrivateZone?.id)?.password !== undefined) {
        password = await new Promise<string | undefined>((resolve, reject) => {
          let password: string;
          Modal.confirm({
            title: 'パスワード入力',
            content: (
              <Input.Password
                onChange={(e) => {
                  password = e.currentTarget.value;
                }}
                placeholder="パスワードを入力して下さい"
              />
            ),
            onOk(...args) {
              resolve(password);
            },
            onCancel(...args) {
              resolve(undefined);
            }
          });
        });
      }

      privateZonePassword.current = password;

      await new Promise<void>((resolve, reject) => {
        socket.current?.emit(
          'updatePosition',
          {
            x,
            y,
            privateZonePassword: password
          },
          (result) => {
            if (result) {
              // nếu không có mật khẩu thì update binh thuong
              map.users.find((e) => e.userId === currentUserRoot.id)!.x = x;
              map.users.find((e) => e.userId === currentUserRoot.id)!.y = y;
              setMap({ ...map });
            } else {
              password &&
                notice({
                  message: 'パスワードが間違いました。'
                });
            }

            resolve();
          }
        );
      });
    }
  };

  async function handleSetPassword() {
    let password: string | undefined = undefined;
    // nếu zone đã có mật khẩu thì mở modal password
    password = await new Promise<string | undefined>((resolve, reject) => {
      let password: string;
      const modal = Modal.confirm({
        title: 'パスワード設定',
        content: (
          <div>
            <Input.Password
              onChange={(e) => {
                password = e.currentTarget.value;
              }}
              placeholder="パスワードを入力して下さい"
              className="mb-3"
            />
            {privateZoneActive?.password ? (
              <Button
                danger
                onClick={() => {
                  modal.destroy(), resolve(undefined);
                  handleResetPassword();
                }}
              >
                パスワード削除
              </Button>
            ) : (
              //     <span onClick={() => {
              //   modal.destroy(),
              //     resolve(undefined)
              //   handleResetPassword()
              // }} className='mt-3 hover:text-sky-500 transform duration-150 cursor-pointer'>パスワード削除</span>
              ''
            )}
          </div>
        ),
        onOk() {
          return new Promise<void>((resolveOnOk, rejectOnOK) => {
            // Your custom logic here
            if (!password || password.length < 1) {
              error({
                message: 'パスワードは1文字以上で入力してください。'
              });
              rejectOnOK();
              return;
            }
            resolveOnOk();
            resolve(password);
          });
        },
        onCancel(...args) {
          resolve(undefined);
        }
      });
    });

    // gọi server để set password
    password &&
      socket.current?.emit(
        'setPassword',
        {
          privateZonePassword: password
        },
        (result) => {
          if (result) {
            success({
              message: 'パスワードが設定されました。'
            });
          } else {
            error({
              message: 'パスワードの設定が失敗しました。'
            });
          }
        }
      );
  }

  function handleResetPassword() {
    socket.current?.emit('setPassword', {}, (result) => {
      if (result) {
        success({
          message: 'パスワードが削除されました。'
        });
      } else {
        error({
          message: 'パスワードの削除が失敗しました。'
        });
      }
    });
  }

  const isUserInPrivateZone = (
    userPositionX: number,
    userPositionY: number,
    privateZonePositionsInMap: typeof privateZonePositions
  ) => {
    return privateZonePositionsInMap?.find((zone) => {
      return (
        userPositionX >= zone.x * scale &&
        userPositionX <= zone.x * scale + (zone.width ?? 0) * scale &&
        userPositionY >= zone.y * scale &&
        userPositionY <= zone.y * scale + (zone.height ?? 0) * scale
      );
    });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleIsStartAudio();
          }}
        >
          {!isStartAudio ? (
            <Space>
              <AudioMutedOutlined className="!inline-flex" /> Off Mic
            </Space>
          ) : (
            <Space>
              <AudioOutlined className="!inline-flex" /> On Mic
            </Space>
          )}
        </span>
      )
    },
    {
      key: '2',
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleIsStartVideo();
          }}
        >
          {!isStartVideo ? (
            <Space>
              <CameraIcon className="!inline-flex" /> Off Video
            </Space>
          ) : (
            <Space>
              <VideoCameraOutlined className="!inline-flex" /> On Video
            </Space>
          )}
        </span>
      )
    },
    {
      key: '3',
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleIsBlur();
          }}
        >
          {!isBlur ? (
            <Space>
              <EyeInvisibleOutlined className="!inline-flex" /> Off Virtual Background
            </Space>
          ) : (
            <Space>
              <EyeOutlined className="!inline-flex" /> On Virtual Background
            </Space>
          )}
        </span>
      )
    }
  ];

  const lastUpdate = useRef<string>(); // Khởi tạo với thời điểm hiện tại

  useEffect(() => {
    if (!window.ipcRenderer) {
      handleCloseExist('デスクトップアプリで開いてください。');
    }
  }, []);

  useEffect(() => {
    const currentUser = map?.users.find((user) => user.userId === currentUserRoot.id)!;
    if (isInStartZone(currentUser)) {
      isInRestZone.current = true;
      return;
    } else {
      isInRestZone.current = false;
    }
  }, [map]);

  useEffect(() => {
    // Tạo interval chạy mỗi 15 phút
    const interval = setInterval(async () => {
      const newLastUpdate = await new Promise<string | undefined>((resolve, reject) => {
        if (!window.ipcRenderer) {
          resolve(undefined);
          return;
        }

        window.ipcRenderer.send('check-working');
        window.ipcRenderer.on('result-check-working', (event, data) => {
          resolve(data);
        });
      });

      if (newLastUpdate === lastUpdate.current) {
        if (!isInRestZone.current) {
          socket.current?.disconnect();
          handleReloadExist('セッションがタイムアウトしました。リロードしてください。');
          clearInterval(interval);
        }
      } else {
        lastUpdate.current = newLastUpdate;
      }
    }, 60000 * 15);

    // Cleanup interval khi component unmount
    return () => clearInterval(interval);
  }, [isInRestZone]); // Mảng dependency rỗng để chỉ chạy một lần khi component mount

  return (
    <div
      ref={clientRef}
      className="h-screen w-full bg-[#FEFAE0] overflow-y-hidden overflow-x-hidden cursor-grab relative no-scrollbar"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUpOrLeave}
      onMouseLeave={handleMouseUpOrLeave}
      onWheel={handleWheel}
      onDoubleClick={handleMapClick}
      tabIndex={-1} // Đặt tabIndex để phần tử có thể nhận focus
      onClick={handleClientFocus} // Focus bàn phím về component hiện tại
    >
      {/* <ZoomContext.Provider value={zmClient}> */}
      {appearZoom && (
        <VideoTalkPoint
          isBlur={isBlur}
          isStartVideo={isStartVideo}
          privateZone={privateZoneActive}
          handleSetPassword={handleSetPassword}
          isStartAudio={isStartAudio}
          key={appearZoom.id}
          zoomArgs={{
            topic: appearZoom.id.toString(),
            signature: appearZoom.signature,
            name: currentUserRoot.name,
            password: '',
            useVideoPlayer: '1',
            enforceGalleryView: '0',
            enforceVB: '0'
          }}
        />
      )}
      {/* </ZoomContext.Provider> */}

      {isAppearUsiApp && (
        <div
          style={{
            // backgroundColor: '',
            zIndex: 999,
            height: '100vh',
            width: '100vw',
            position: 'fixed'
          }}
          onClick={() => setIsAppearUsiApp(false)}
        >
          {' '}
        </div>
      )}

      <UsiApp handleAppear={isAppearUsiApp} USIURL={USIURL} />

      <div
        ref={mapRef}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
          backgroundImage: `url(${MapPNG})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: `${mapSize.width}px`,
          height: `${mapSize.height}px`,
          margin: '0 auto',
          transformOrigin: '0px 0px',
          transform: `scale(1) translate(0px, 0px)`
        }}
      >
        <div
          style={{
            width: `${START_ZONE.width}px`,
            height: `${START_ZONE.height}px`,
            position: 'absolute',
            top: `${START_ZONE.y}px`,
            left: `${START_ZONE.x}px`,
            // START_ZONE k cần làm mờ nữa
            // backgroundColor: 'rgba(191, 236, 255, 0.2)',
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            zIndex: 9
          }}
        ></div>

        {map?.users?.map?.((user) => (
          <UserAvatar
            key={user.userId}
            name={user.name}
            positionX={user.x}
            positionY={user.y}
            avatarWidth={avatarSize}
            avatarHeight={avatarSize}
            avatarImage={`url(${user.avatarURL ? `${user.avatarURL}&token=${accessToken}` : 'user1.jpg'})`}
          />
        ))}

        {map?.talkPoints?.map?.((talkPoint) => (
          <TalkPoint
            key={talkPoint.id}
            positionX={talkPoint.x}
            positionY={talkPoint.y}
            talkWidth={distance}
            talkHeight={distance}
            talkImage={People}
          />
        ))}

        {privateZonePositions.map((zone) => (
          <PrivateZone
            key={`${zone.id} ${zonesOtherUserIn?.has(zone.id)}`}
            id={zone.id}
            positionX={zone.x}
            positionY={zone.y}
            width={zone.width}
            height={zone.height}
            privateZone={map?.privateZones?.find((e) => e.id === zone.id)}
            forceHover={zonesOtherUserIn?.has(zone.id) ?? false}
            isInPrivateZone={(privateZoneActive?.id === zone.id || zonesOtherUserIn?.has(zone.id)) ?? false}
          />
        ))}

        {privateZoneActive && <PrivateZoneActive privateZoneActive={privateZoneActive} mapSize={mapSize} />}
      </div>

      <div className="fixed top-0 flex-col w-full p-4 z-50">
        <div className="flex justify-end">
          <Space onDoubleClick={stopDoubleClickPropagation}>
            {privateZoneActive && (
              <Button
                onClick={() => {
                  handleSetPassword();
                }}
                icon={privateZoneActive.password ? <LockOutlined /> : <UnlockOutlined />}
              />
            )}
            <Dropdown menu={{ items }} trigger={['click']} open={visibleSetting} onOpenChange={setVisibleSetting}>
              <Button
                icon={<ProjectOutlined className="!inline-flex" />}
                onClick={() => setVisibleSetting(!visibleSetting)}
              >
                デフォルト設定
              </Button>
            </Dropdown>

            <Button onClick={handleAppearUsiApp} icon={<AppstoreAddOutlined className="!inline-flex" />}>
              USI App
            </Button>
            <Button onClick={() => handleZoom('in')} icon={<ZoomInOutlined />} />
            <Button onClick={() => handleZoom('out')} icon={<ZoomOutOutlined />} />
          </Space>
        </div>

        {!appearZoom && <Preview isBlur={isBlur} isStarted={true} />}
      </div>
    </div>
  );
}

export default MapOffice;
