import React, { useState } from 'react';
import './usi-app.scss';
import { Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

interface UsiAppProps {
  handleAppear: boolean;
  USIURL: string;
}

const UsiApp: React.FC<UsiAppProps> = ({ handleAppear, USIURL }) => {

  const [resize, setResize] = useState(false);

  return (
    <div>
      <div
        className={`fixed flex items-center z-[1000] ${handleAppear ? 'slide-in' : 'slide-out'}`}
        style={{
          top: '70px',
          right: '50px',
          height: 'calc(100vh - 70px)',
          width: resize ? 'calc(100vw - 60px)' : '450px',
          transition: 'width 0.2s',
        }}
      >
        <Button
          onClick={() => setResize(!resize)}
          icon={resize ? <CaretRightOutlined /> : <CaretLeftOutlined /> } 
          className='mr-4'
          style={{ width: '47px'}}
          size='large'
        />
        <iframe
          src={`${USIURL}chat-app/group-list`}
          width="100%"
          height="90%"
          title="USI App"
          className="border-emerald-200 border-solid border-[7px] rounded-xl"
        />
      </div>
    </div>
  );
}

export default UsiApp;