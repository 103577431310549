import React, { useState } from 'react';
import { PrivateZoneType } from '../../index-types';

interface Props {
  privateZoneActive: PrivateZoneType
  mapSize: {
    width: number;
    height: number;
  }
}

function PrivateZoneActive({
  privateZoneActive,
  mapSize
}: Props) {
  const calculateClipPath = (privateZoneActive: PrivateZoneType): string | undefined => {
    const { x, y, width, height } = privateZoneActive;

    return `
      polygon(
        0% 0%, 
        0% 100%, 
        ${(x / mapSize.width) * 100}% 100%,
        ${(x / mapSize.width) * 100}% ${(y / mapSize.height) * 100}%,
        ${((x + width) / mapSize.width) * 100}% ${(y / mapSize.height) * 100}%,
        ${((x + width) / mapSize.width) * 100}% ${((y + height) / mapSize.height) * 100}%,
        ${(x / mapSize.width) * 100}% ${((y + height) / mapSize.height) * 100}%,
        ${(x / mapSize.width) * 100}% 100%,
        100% 100%, 
        100% 0%
      )
    `;
  };


  return (
    <div
      className={`absolute inset-0 bg-black bg-opacity-50 pointer-events-none z-5`}
      style={{
        clipPath: calculateClipPath(privateZoneActive),
        transition: 'clip-path 0.3s ease, opacity 0.3s ease',
      }}
    />);
}

export default PrivateZoneActive;
