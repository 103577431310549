import { Button, message, Modal, Space } from 'antd';
import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import Video from '../video/video';
import { stopDoubleClickPropagation } from '../../utils/stopDoubleClickPropagation';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import produce from 'immer';
import ZoomContext from '../../context/zoom-context';
import { MediaStream, PrivateZoneType } from '../../index-types';
import ZoomMediaContext from '../../context/media-context';
import LoadingLayerOffice from '../../component/loading-layer-office';
import { useZoomLimit } from '../../component/LimitContext';
import { LockOutlined, UnlockOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

interface VideoTalkPointProps {
  isStartAudio: boolean;
  isStartVideo: boolean;
  isBlur: boolean;
  privateZone?: PrivateZoneType;
  handleSetPassword: any;
  zoomArgs: {
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;
    useVideoPlayer?: string;
  };
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

function VideoTalkPoint(props: VideoTalkPointProps) {
  const {
    isStartAudio = false,
    isStartVideo = false,
    isBlur = false,
    privateZone,
    handleSetPassword,
    zoomArgs: {
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      enforceVB,
      customerJoinId,
      lang,
      useVideoPlayer
    }
  } = props;

  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(false);
  const [countUser, setCountUser] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const zmClient = useContext(ZoomContext);
  const limit = useZoomLimit();
  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach = Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);

  // if (galleryViewWithAttach) {
  //   console.log({
  //     galleryViewWithAttach,
  //     use: '<video-player-container> video tag render video',
  //     doc: 'https://marketplacefront.zoom.us/sdk/custom/web/modules/Stream.html#attachVideo'
  //   });
  // } else {
  //   console.log({
  //     galleryViewWithAttach,
  //     use: '<canvas>',
  //     doc: 'https://marketplacefront.zoom.us/sdk/custom/web/modules/Stream.html#startVideo'
  //   });
  // }

  useEffect(() => {
    const init = async () => {
      // await zmClient.init('en-US', `${window.location.origin}/lib`, {
      //   webEndpoint,
      //   enforceMultipleVideos: galleryViewWithoutSAB,
      //   enforceVirtualBackground: vbWithoutSAB,
      //   stayAwake: true,
      //   patchJsMedia: true,
      //   leaveOnPageUnload: false
      // });
      try {
        setLoadingText('会議に参加する...');
        await zmClient.join(topic, signature, name, password).catch((e) => {
          // console.log(e);
        });
        const stream = zmClient.getMediaStream();
        await stream?.startAudio();

        if (isStartVideo) {
          await stream?.startVideo();
        }

        if (isStartAudio) {
          await stream?.unmuteAudio();
        } else {
          await stream?.muteAudio();
        }

        if (isBlur) {
          stream?.updateVirtualBackgroundImage('blur');
        }

        setMediaStream(stream);
        setIsSupportGalleryView(stream.isSupportMultipleVideos());
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        // message.error(e.reason);
      }
    };
    const leave = async () => {
      await mediaStream?.stopAudio();
      await mediaStream?.stopShareScreen();
      await mediaStream?.stopVideo();
      await zmClient.leave();
    };
    limit(() => init());
    return () => {
      limit(() => leave());
    };
  }, [
    signature,
    zmClient,
    topic,
    name,
    password,
    webEndpoint,
    galleryViewWithoutSAB,
    customerJoinId,
    lang,
    vbWithoutSAB
  ]);

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host'
          });
        }
      }
    },
    [isFailover, zmClient]
  );

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  useEffect(() => {
    zmClient.on('user-updated', (payload) => {
      setCountUser(zmClient.getAllUser().length);
    });

    zmClient.on('user-removed', (payload) => {
      setCountUser(zmClient.getAllUser().length);
    });
  }, []);

  return (
    <div onDoubleClick={stopDoubleClickPropagation} style={{ display: 'block' }}>
      {loading && <LoadingLayerOffice content={loadingText} />}
      {!loading && (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Space
            style={{
              position: 'absolute',
              top: '65px',
              right: 'calc(4vw + 10px)',
              zIndex: 52
            }}
          >
            {/* {privateZone && (
              <Button
                onClick={() => {
                  handleSetPassword();
                }}
                icon={privateZone.password ? <LockOutlined /> : <UnlockOutlined />}
              />
            )} */}

            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              icon={isExpanded ? <ZoomOutOutlined /> : <ZoomInOutlined />}
            />
          </Space>
          <Video
            history={{} as any}
            location={{} as any}
            match={{} as any}
            styleMapOffice={{
              width: isExpanded ? '92vw' : `${300 * countUser}px`,
              height: isExpanded ? 'calc(100vh - 128px)' : '300px',
              // filter: isExpanded ? 'none' : 'opacity(0.7)',
              position: 'fixed',
              right: '0',
              marginTop: '55px',
              marginRight: '4vw',
              maxWidth: '92vw',
              borderRadius: '10px',
              pointerEvents: 'auto',
              zIndex: '40',
              transition: 'all 0.3s'
            }}
          />
        </ZoomMediaContext.Provider>
      )}
    </div>
  );
}

export default VideoTalkPoint;
