export const distance = 500
export const avatarSize = 150
export const talkSize = 50
export const startPointX = 70
export const startPointY = 830
//Kích thước mặc định
export const mapWidth = 4793
export const mapHeight = 2937
export const stepDistance = 30

export const START_ZONE = {
  width: 2065,
  height: 1625,
  x: 0,
  y: 0
}

export const privateZonePositions = [
  { id: 1, x: 2300, y: 220, width: 440, height: 440, type: 'table' },
  { id: 2, x: 3110, y: 220, width: 440, height: 440, type: 'table' },
  { id: 3, x: 3920, y: 220, width: 440, height: 440, type: 'table' },
  { id: 4, x: 2300, y: 1190, width: 440, height: 440, type: 'table' },
  { id: 5, x: 3110, y: 1190, width: 440, height: 440, type: 'table' },
  { id: 6, x: 3920, y: 1190, width: 440, height: 440, type: 'table' },
  { id: 7, x: 2069, y: 1630, width: 2724, height: 1293, type: 'room' },
  //Hàng 1
  { id: 8, x: 60, y: 1660, width: 400, height: 300, type: 'table' },
  { id: 9, x: 535, y: 1660, width: 400, height: 300, type: 'table' },
  { id: 10, x: 1010, y: 1660, width: 400, height: 300, type: 'table' },
  { id: 11, x: 1490, y: 1660, width: 400, height: 300, type: 'table' },
  //Hàng 2
  { id: 12, x: 60, y: 2060, width: 400, height: 300, type: 'table' },
  { id: 13, x: 535, y: 2060, width: 400, height: 300, type: 'table' },
  { id: 14, x: 1010, y: 2060, width: 400, height: 300, type: 'table' },
  { id: 15, x: 1490, y: 2060, width: 400, height: 300, type: 'table' },
  //Hàng 3
  { id: 16, x: 60, y: 2460, width: 400, height: 300, type: 'table' },
  { id: 17, x: 535, y: 2460, width: 400, height: 300, type: 'table' },
  { id: 18, x: 1010, y: 2460, width: 400, height: 300, type: 'table' },
  { id: 19, x: 1490, y: 2460, width: 400, height: 300, type: 'table' }
]
