import React, { useEffect, useRef, useState } from 'react';
import Video from '../video/video';
import { io, Socket } from 'socket.io-client';
import { ClientToServerEventsCallService, ServerToClientEventsCallService } from "server/src/types/call-service-type";

const calledUserId = new URLSearchParams(window.location.search).get('calledUserId');
const accessToken = new URLSearchParams(window.location.search).get('accessToken');

const Call = () => {
  const socket = useRef<Socket<ServerToClientEventsCallService, ClientToServerEventsCallService>>();

  useEffect(() => {
    if (calledUserId) {
      //socket io định nghĩa
      if (!socket.current) {
        const socketTmp: Socket<ServerToClientEventsCallService, ClientToServerEventsCallService> = io('https://8086--main--phong--phong.hirogo-dev.com/', {
          reconnectionDelayMax: 10000,
          auth: {
            token: accessToken,
          },
        });

        socket.current = socketTmp
      }


      socket.current.emit("createCall", {
        userID: calledUserId,
      }, (e) => {
        console.log(e);

      })
    }
  }, [])
  return (
    <>
      <Video history={{} as any} location={{} as any} match={{} as any}></Video>
    </>
  );
};

export default Call;