import { useContext } from 'react';
import { Button, Tooltip, Menu, Dropdown } from 'antd';
import { CheckOutlined, MenuOutlined, VideoCameraAddOutlined, VideoCameraFilled } from '@ant-design/icons';
import ZoomMediaContext from '../../../context/media-context';
import classNames from 'classnames';
import { MediaDevice } from '../video-types';
import { getAntdDropdownMenu, getAntdItem, MenuItem } from './video-footer-utils';
interface CameraButtonProps {
  isOfficePath: boolean;
  isStartedVideo: boolean;
  isMirrored?: boolean;
  isBlur?: boolean;
  isPreview?: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  onMirrorVideo?: () => void;
  onBlurBackground?: () => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
}
const CameraButton = (props: CameraButtonProps) => {
  const {
    isOfficePath,
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    isMirrored,
    isBlur,
    isPreview,
    onCameraClick,
    onSwitchCamera,
    onMirrorVideo,
    onBlurBackground
  } = props;
  const { mediaStream } = useContext(ZoomMediaContext);
  const onMenuItemClick = (payload: { key: any }) => {
    if (payload.key === 'mirror') {
      onMirrorVideo?.();
    } else if (payload.key === 'blur') {
      onBlurBackground?.();
    } else {
      onSwitchCamera(payload.key);
    }
  };
  const menuItems =
    cameraList &&
    cameraList.length > 0 &&
    ([
      getAntdItem(
        'カメラを選択する',
        'camera',
        undefined,
        cameraList.map((item) =>
          getAntdItem(item.label, item.deviceId, item.deviceId === activeCamera && <CheckOutlined />)
        ),
        'group'
      ),
      !isPreview && getAntdItem('ビデオを鏡映しにする', 'mirror', isMirrored && <CheckOutlined />),
      mediaStream?.isSupportVirtualBackground()
        ? getAntdItem('背景をぼかす', 'blur', isBlur && <CheckOutlined />)
        : getAntdItem('背景をマスクする', 'blur'),
    ].filter(Boolean) as MenuItem[]);
  return (
    <div className={classNames('camera-footer', className)}>
      {isStartedVideo && menuItems ? (
        !isOfficePath ? (
          <Dropdown.Button
            className="vc-dropdown-button"
            size="large"
            menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
            onClick={onCameraClick}
            trigger={['click']}
            type="primary"
            placement="topRight"
          >
            <VideoCameraFilled />
          </Dropdown.Button>
        ) : (
         <Dropdown
          menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
          trigger={['click']}
          placement="topRight"
         >
          <Button
            className="vc-button"
            icon={<MenuOutlined />}
            ghost={false}
            type="primary"
            shape="circle"
            size="large"
          />
         </Dropdown> 
        )
      ) : (
        <Tooltip title={`${isStartedVideo ? 'カメラを閉じる' : 'カメラを起動する'}`}>
          <Button
            className="vc-button"
            icon={isStartedVideo ? <VideoCameraFilled /> : <VideoCameraAddOutlined />}
            ghost={false}
            type="primary"
            shape="circle"
            size="large"
            onClick={onCameraClick}
          />
        </Tooltip>
      )}
    </div>
  );
};
export default CameraButton;
