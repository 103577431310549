import { useState, useCallback, useContext, useEffect, MutableRefObject } from 'react';
import classNames from 'classnames';
import { message, Modal, Form, Select, Checkbox, Tooltip } from 'antd';
import { SoundOutlined } from '@ant-design/icons';
import ZoomContext from '../../../context/zoom-context';
import CameraButton from './camera';
import MicrophoneButton from './microphone';
import { ScreenShareButton } from './screen-share';
import AudioVideoStatisticModal from './audio-video-statistic';
import ZoomMediaContext from '../../../context/media-context';
import { useUnmount, useMount } from '../../../hooks';
import { MediaDevice } from '../video-types';
import './video-footer.scss';
import { isAndroidOrIOSBrowser, isIOSMobile } from '../../../utils/platform';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from '../video-constants';
import {
  DialoutState,
  RecordingStatus,
  MutedSource,
  AudioChangeAction,
  DialOutOption,
  VideoCapturingState,
  SharePrivilege,
  MobileVideoFacingMode,
  LiveStreamStatus,
  ShareStatus
} from '@zoom/videosdk';
import { LeaveButton } from './leave';
import { TranscriptionSubtitle } from './transcription-subtitle';
import { IconFont } from '../../../component/icon-font';
import { VideoMaskModel } from './video-mask-modal';
import { useLocation } from 'react-router-dom';
import ScreenItem from './screen-item';
interface VideoFooterProps {
  className?: string;
  selfShareCanvas?: HTMLCanvasElement | HTMLVideoElement | null;
  sharing?: boolean;
}

const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter = (props: VideoFooterProps) => {
  const { className, selfShareCanvas, sharing } = props;
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const recordingClient = zmClient.getRecordingClient();
  const [isStartedAudio, setIsStartedAudio] = useState(
    zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== ''
  );
  const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
  const [audio, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);
  const [isSupportPhone, setIsSupportPhone] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [isMirrored, setIsMirrored] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isMuted, setIsMuted] = useState(!!zmClient.getCurrentUserInfo()?.muted);
  const [activeMicrophone, setActiveMicrophone] = useState(mediaStream?.getActiveMicrophone());
  const [activeSpeaker, setActiveSpeaker] = useState(mediaStream?.getActiveSpeaker());
  const [activeCamera, setActiveCamera] = useState(mediaStream?.getActiveCamera());
  const [micList, setMicList] = useState<MediaDevice[]>(mediaStream?.getMicList() ?? []);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(mediaStream?.getSpeakerList() ?? []);
  const [cameraList, setCameraList] = useState<MediaDevice[]>(mediaStream?.getCameraList() ?? []);
  const [statisticVisible, setStatisticVisible] = useState(false);
  const [selecetedStatisticTab, setSelectedStatisticTab] = useState('audio');
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
  const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
  const [caption, setCaption] = useState({ text: '', isOver: false });
  const [activePlaybackUrl, setActivePlaybackUrl] = useState('');
  const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);
  // Video Mask
  const [videoMaskVisible, setVideoMaskVisible] = useState(false);
  const location = useLocation();
  const [isOfficePath, setIsOfficePath] = useState(false);
  const [isSecondaryAudioStarted, setIsSecondaryAudioStarted] = useState(false);
  const [screenSources, setScreenSources] = useState<any[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState('');
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [secondaryMicForm] = Form.useForm();
  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo();
      setIsStartedVideo(false);
    } else {
      const startVideoOptions = {
        hd: true,
        fullHd: true,
        ptz: mediaStream?.isBrowserSupportPTZ(),
        originalRatio: true
      };
      if (mediaStream?.isSupportVirtualBackground() && isBlur) {
        Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
      }
      await mediaStream?.startVideo(startVideoOptions);
      if (!mediaStream?.isSupportMultipleVideos()) {
        const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
        mediaStream?.renderVideo(
          canvasElement,
          zmClient.getSessionInfo().userId,
          canvasElement.width,
          canvasElement.height,
          0,
          0,
          3
        );
      }
      // const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
      // if (mediaStream?.isRenderSelfViewWithVideoElement() && !temporaryException) {
      //   const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
      //   if (videoElement) {
      //     await mediaStream?.startVideo({ videoElement });
      //   }
      // } else {
      //   const startVideoOptions = { hd: true, fullHd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
      //   if (mediaStream?.isSupportVirtualBackground() && isBlur) {
      //     Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
      //   }
      //   await mediaStream?.startVideo(startVideoOptions);
      //   if (!mediaStream?.isSupportMultipleVideos()) {
      //     const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
      //     mediaStream?.renderVideo(
      //       canvasElement,
      //       zmClient.getSessionInfo().userId,
      //       canvasElement.width,
      //       canvasElement.height,
      //       0,
      //       0,
      //       3
      //     );
      //   }
      // }

      setIsStartedVideo(true);
    }
  }, [mediaStream, isStartedVideo, zmClient, isBlur]);
  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
      } else {
        await mediaStream?.muteAudio();
      }
    } else {
      try {
        await mediaStream?.startAudio({ highBitrate: true });
      } catch (e: any) {
        if (e.type === 'INSUFFICIENT_PRIVILEGES' && e.reason === 'USER_FORBIDDEN_MICROPHONE') {
          setIsMicrophoneForbidden(true);
        }
        console.warn(e);
      }
      setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);
  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split('|');
      if (type === 'microphone') {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === 'speaker') {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === 'leave audio') {
        if (audio === 'computer') {
          await mediaStream.stopAudio();
        } else if (audio === 'phone') {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
      } else if (type === 'statistic') {
        setSelectedStatisticTab('audio');
        setStatisticVisible(true);
      } else if (type === 'secondary audio') {
        if (isSecondaryAudioStarted) {
          await mediaStream.stopSecondaryAudio();
          setIsSecondaryAudioStarted(false);
        } else {
          Modal.confirm({
            title: 'Start secondary audio',
            content: (
              <Form form={secondaryMicForm}>
                <Form.Item label="Microphone" name="mic" required>
                  <Select
                    options={mediaStream.getMicList().map((item) => ({
                      value: item.deviceId,
                      label: item.label,
                      disabled: item.deviceId === mediaStream.getActiveMicrophone()
                    }))}
                  />
                </Form.Item>
                <Form.Item label="Contraintes" name="constraints">
                  <Checkbox.Group
                    options={[
                      { label: 'AGC', value: 'autoGainControl' },
                      {
                        label: 'ANC',
                        value: 'noiseSuppression'
                      },
                      {
                        label: 'AEC',
                        value: 'echoCancellation'
                      }
                    ]}
                  />
                </Form.Item>
              </Form>
            ),
            onOk: async () => {
              try {
                const data = await secondaryMicForm.validateFields();
                const { mic, constraints } = data;
                const option = {};
                if (constraints) {
                  constraints.forEach((key: string) => {
                    Object.assign(option, { [`${key}`]: true });
                  });
                }
                await mediaStream.startSecondaryAudio(mic, option);
                setIsSecondaryAudioStarted(true);
              } catch (e) {
                console.warn(e);
              }
            }
          });
        }
      }
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
        setActivePlaybackUrl('');
      }
    }
  };
  const onMirrorVideo = async () => {
    await mediaStream?.mirrorVideo(!isMirrored);
    setIsMirrored(!isMirrored);
  };
  const onBlurBackground = async () => {
    const isSupportVirtualBackground = mediaStream?.isSupportVirtualBackground();
    if (isSupportVirtualBackground) {
      if (isBlur) {
        await mediaStream?.updateVirtualBackgroundImage(undefined);
      } else {
        await mediaStream?.updateVirtualBackgroundImage('blur');
      }
    } else {
      setVideoMaskVisible(true);
    }

    setIsBlur(!isBlur);
  };
  const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
    await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
  };
  const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
    if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
      await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 3000);
      });
    }
    return Promise.resolve();
  };
  const onHostAudioMuted = useCallback(
    (payload: any) => {
      const { action, source, type } = payload;
      if (action === AudioChangeAction.Join) {
        setIsStartedAudio(true);
        setAudio(type);
        setTimeout(() => {
          setIsMuted(!!zmClient.getCurrentUserInfo()?.muted);
        }, 1000);
      } else if (action === AudioChangeAction.Leave) {
        setIsStartedAudio(false);
      } else if (action === AudioChangeAction.Muted) {
        setIsMuted(true);
        if (source === MutedSource.PassiveByMuteOne) {
          message.info('Host muted you');
        }
      } else if (action === AudioChangeAction.Unmuted) {
        setIsMuted(false);
        if (source === 'passive') {
          message.info('Host unmuted you');
        }
      }
    },
    [zmClient]
  );

  const onScreenShareClick = useCallback(async () => {

    // Xu li cua electron
    if (window.ipcRenderer) {
      const sources = await window.api.main.getScreenSources();
      setScreenSources(sources);
      setIsListVisible(true);

    } else {
      // Xu li cua web
      try {
        if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
          await mediaStream?.startShareScreen(selfShareCanvas, { requestReadReceipt: true });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [mediaStream, selfShareCanvas]);

  const onLeaveClick = useCallback(async () => {
    window.close();
    await zmClient.leave();
  }, [zmClient]);

  const onEndClick = useCallback(async () => {
    await zmClient.leave(true);
    window.close();
  }, [zmClient]);

  const onPassivelyStopShare = useCallback(({ reason }: any) => {
    console.log('passively stop reason:', reason);
  }, []);
  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      if (!isAndroidOrIOSBrowser()) {
        setCameraList(mediaStream.getCameraList());
      }
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onDialOutChange = useCallback((payload: any) => {
    setPhoneCallStatus(payload.code);
  }, []);

  const onRecordingClick = async (key: string) => {
    switch (key) {
      case 'Record': {
        await recordingClient?.startCloudRecording();
        break;
      }
      case 'Resume': {
        await recordingClient?.resumeCloudRecording();
        break;
      }
      case 'Stop': {
        await recordingClient?.stopCloudRecording();
        break;
      }
      case 'Pause': {
        await recordingClient?.pauseCloudRecording();
        break;
      }
      case 'Status': {
        break;
      }
      default: {
        await recordingClient?.startCloudRecording();
      }
    }
  };
  const onVideoCaptureChange = useCallback((payload: any) => {
    if (payload.state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, []);
  const onShareAudioChange = useCallback(
    (payload: any) => {
      const { state } = payload;
      if (state === 'on') {
        if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
          setIsComputerAudioDisabled(true);
        }
      } else if (state === 'off') {
        setIsComputerAudioDisabled(false);
      }
    },
    [mediaStream]
  );
  const onHostAskToUnmute = useCallback((payload: any) => {
    const { reason } = payload;
    console.log(`Host ask to unmute the audio.`, reason);
  }, []);

  const onCaptionStatusChange = useCallback((payload: any) => {
    const { autoCaption } = payload;
    if (autoCaption) {
      message.info('自動ライブ文字起こしが有効になりました！');
    }
  }, []);

  const onCaptionMessage = useCallback((payload: any) => {
    const { text, done } = payload;
    setCaption({
      text,
      isOver: done
    });
  }, []);

  const onCanSeeMyScreen = useCallback(() => {
    message.info('他の方は共有画面を見られるようになりました', 1);
  }, []);
  const onSelectVideoPlayback = useCallback(
    async (url: string) => {
      if (activePlaybackUrl !== url) {
        await mediaStream?.switchCamera({ url, loop: true });
        if (isStartedAudio) {
          await mediaStream?.switchMicrophone({ url, loop: true });
        } else {
          await mediaStream?.startAudio({ mediaFile: { url, loop: true } });
        }
        setActivePlaybackUrl(url);
      }
    },
    [isStartedAudio, activePlaybackUrl, mediaStream]
  );
  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('dialout-state-change', onDialOutChange);
    zmClient.on('video-capturing-change', onVideoCaptureChange);
    zmClient.on('share-audio-change', onShareAudioChange);
    zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
    zmClient.on('caption-status', onCaptionStatusChange);
    zmClient.on('caption-message', onCaptionMessage);
    zmClient.on('share-can-see-screen', onCanSeeMyScreen);

    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
      zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('dialout-state-change', onDialOutChange);
      zmClient.off('video-capturing-change', onVideoCaptureChange);
      zmClient.off('share-audio-change', onShareAudioChange);
      zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
      zmClient.off('caption-status', onCaptionStatusChange);
      zmClient.off('caption-message', onCaptionMessage);
      zmClient.off('share-can-see-screen', onCanSeeMyScreen);

    };
  }, [
    zmClient,
    onHostAudioMuted,
    onPassivelyStopShare,
    onDeviceChange,
    onDialOutChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onHostAskToUnmute,
    onCaptionStatusChange,
    onCaptionMessage,
    onCanSeeMyScreen
  ]);
  // useUnmount(() => {
  //   if(!isOfficePath) {
  //     if (zmClient.getSessionInfo().isInMeeting) {
  //       if (isStartedAudio) {
  //         mediaStream?.stopAudio();
  //       }
  //       if (isStartedVideo) {
  //         mediaStream?.stopVideo();
  //       }
  //       mediaStream?.stopShareScreen();
  //     }
  //   }
  // });
  useMount(() => {
    if (mediaStream) {
      setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
      setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
      setSharePrivileg(mediaStream.getSharePrivilege());
      if (isAndroidOrIOSBrowser()) {
        setCameraList([
          { deviceId: MobileVideoFacingMode.User, label: 'Front-facing' },
          { deviceId: MobileVideoFacingMode.Environment, label: 'Rear-facing' }
        ]);
      }
    }
  });

  useEffect(() => {
    console.log("cuong location.pathname", location.pathname);

    if (location.pathname === "/office") {
      setIsOfficePath(true);
    } else {
      setIsOfficePath(false);
    }
  }, [location.pathname]);

  const handleScreenSelect = (sourceId: string) => {
    if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
      (async () => {
        await mediaStream?.startShareScreen(selfShareCanvas, {
          requestReadReceipt: true,
          sourceId: sourceId,
        });
      })();
    }
    setIsListVisible(false);
  };

  const handleCloseList = () => {
    setIsListVisible(false);
  };

  return (
    <div className={classNames('video-footer', className)}>
      {isAudioEnable && (
        <MicrophoneButton
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          isSupportPhone={isSupportPhone}
          audio={audio}
          phoneCountryList={phoneCountryList}
          onPhoneCallClick={onPhoneCall}
          onPhoneCallCancel={onPhoneCallCancel}
          phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
          onMicrophoneClick={onMicrophoneClick}
          onMicrophoneMenuClick={onMicrophoneMenuClick}
          microphoneList={micList}
          speakerList={speakerList}
          activeMicrophone={activeMicrophone}
          activeSpeaker={activeSpeaker}
          disabled={isComputerAudioDisabled}
          isMicrophoneForbidden={isMicrophoneForbidden}
        />
      )}
      <CameraButton
        isOfficePath={isOfficePath}
        isStartedVideo={isStartedVideo}
        onCameraClick={onCameraClick}
        onSwitchCamera={onSwitchCamera}
        onBlurBackground={onBlurBackground}
        onMirrorVideo={onMirrorVideo}
        cameraList={cameraList}
        activeCamera={activeCamera}
        isMirrored={isMirrored}
        isBlur={isBlur}
      />
      {sharing && (
        <ScreenShareButton
          sharePrivilege={sharePrivilege}
          isHostOrManager={zmClient.isHost() || zmClient.isManager()}
          onScreenShareClick={onScreenShareClick}
          onSharePrivilegeClick={async (privilege) => {
            await mediaStream?.setSharePrivilege(privilege);
            setSharePrivileg(privilege);
          }}
          />
      )}
      {isSecondaryAudioStarted && (
        <Tooltip title="Secondary audio on">
          <SoundOutlined style={{ position: 'fixed', top: '45px', left: '10px', color: '#f60', fontSize: '24px' }} />
        </Tooltip>
      )}
      {!isOfficePath && <LeaveButton onLeaveClick={onLeaveClick} isHost={zmClient.isHost()} onEndClick={onEndClick} />}

      <AudioVideoStatisticModal
        visible={statisticVisible}
        setVisible={setStatisticVisible}
        defaultTab={selecetedStatisticTab}
        isStartedAudio={isStartedAudio}
        isMuted={isMuted}
        isStartedVideo={isStartedVideo}
      />
      {!mediaStream?.isSupportVirtualBackground() && (
        <VideoMaskModel visible={videoMaskVisible} setVisible={setVideoMaskVisible} isMirrored={isMirrored} />
      )}
      {isListVisible && screenSources.length > 0 && (
        <div
          className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full max-w-4xl bg-white p-4 rounded-lg shadow-lg z-50"
          style={{ maxHeight: '85vh' }}
        >
          <button
            onClick={handleCloseList}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          >
            <span className="text-2xl">&times;</span> {/* The X icon */}
          </button>
          <h3 className="text-xl font-semibold mb-4 text-center">Select a Screen Source:</h3>
          <div className="overflow-y-auto max-h-[60vh]">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {screenSources.map((source: any) => (
                <div
                  key={source.id}
                  onClick={() => handleScreenSelect(source.id)}
                  className="cursor-pointer bg-white border rounded-lg p-4 shadow-md hover:bg-gray-100"
                >
                  <ScreenItem
                    name={source.name}
                    id={source.id}
                    thumbnailURL={source.thumbnailURL}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default VideoFooter;
