import React from 'react'

interface sourceScreenProps {
    name: string,
    id: string,
    thumbnailURL: string,
}

const ScreenItem = ({ name, id, thumbnailURL } : sourceScreenProps) => {
    const truncatedName = name.length > 8 ? name.slice(0, 8) + "..." : name;

  return (
    <div>
      <img src={thumbnailURL} alt={name} className="w-full h-32 object-cover rounded-md mb-2" />
      <p className="text-center">{truncatedName}</p>
    </div>
  );
}

export default ScreenItem