import { useEffect, useState } from 'react';
import useWindownSize from './useWindownSize';


const useMapInteractions = (
  clientRef: React.RefObject<HTMLDivElement>,
  mapRef: React.RefObject<HTMLDivElement>,
  mapWidth: number,
  mapHeight: number
) => {

  const [isDragging, setIsDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [pointX, setPointX] = useState(0);
  const [pointY, setPointY] = useState(0);
  const [scale, setScale] = useState(1);
  const { windowWidth, windowHeight } = useWindownSize()

  const minScale = Math.min(windowHeight / mapHeight, windowWidth / mapWidth) - 0.01
  const maxScale = 2 * windowHeight / mapHeight

  const setTransform = () => {
    if (mapRef.current) {
      mapRef.current.style.transform = `translate(${pointX}px, ${pointY}px) scale(${scale})`;
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setStart({ x: e.clientX - pointX, y: e.clientY - pointY });
    setIsDragging(true);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isDragging) return;
    if (scale === minScale) return;

    const newPointX = e.clientX - start.x;
    const newPointY = e.clientY - start.y;

    setPointX(newPointX);
    setPointY(newPointY);
    setTransform();
  };


  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  const handleWheel = (e: React.WheelEvent) => {
    const rect = clientRef.current?.getBoundingClientRect();
    if (!rect) return;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Tính toán vị trí tương đối của con trỏ chuột với tỉ lệ zoom hiện tại
    const offsetX = (mouseX - pointX) / scale;
    const offsetY = (mouseY - pointY) / scale;

    // Kiểm tra xem đang zoom in hay zoom out
    const delta = e.deltaY < 0 ? 1.2 : 0.8;
    // const newScale = scale * delta
    const newScale = Math.max(minScale, Math.min(scale * delta, maxScale));

    // Cập nhật vị trí (pointX, pointY) để giữ cho điểm chuột ở cùng vị trí khi zoom
    setPointX(mouseX - offsetX * newScale);
    setPointY(mouseY - offsetY * newScale);

    if (newScale === minScale) {
      setPointX((windowWidth - mapWidth * newScale) / 2);
      setPointY((windowHeight - mapHeight * newScale) / 2);
    }

    setScale(newScale);

    setTransform();
  };

  const handleZoom = (zoomType: 'in' | 'out') => {
    const rect = clientRef.current?.getBoundingClientRect();
    if (!rect) return;

    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const offsetX = (centerX - pointX) / scale;
    const offsetY = (centerY - pointY) / scale;

    const delta = zoomType === 'in' ? 1.2 : 0.8;
    const newScale = Math.max(minScale, Math.min(scale * delta, maxScale));

    if (newScale === minScale) {
      setPointX((windowWidth - mapWidth * newScale) / 2);
      setPointY((windowHeight - mapHeight * newScale) / 2);
    }

    setPointX(centerX - offsetX * newScale);
    setPointY(centerY - offsetY * newScale);
    setScale(newScale);

    setTransform();
  };

  useEffect(() => {
    setTransform();
  }, [pointX, pointY, scale]);

  useEffect(() => {
    setScale(minScale)
    setPointX((windowWidth - mapWidth * minScale) / 2);
    setPointY((windowHeight - mapHeight * minScale) / 2);
    setTransform();
  }, [mapHeight, mapWidth, windowWidth, windowHeight]);

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUpOrLeave,
    handleWheel,
    isDragging,
    scale,
    handleZoom,
    pointX,
    pointY
  };
};

export default useMapInteractions;
