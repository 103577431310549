import { avatarSize, START_ZONE } from 'server/src/mapConst';
import { SocketDataOfficeService } from 'server/src/types/office-service-type';

export const isInStartZone = (currentUser: SocketDataOfficeService ) => {
        if (!currentUser) {
            return false;
        }

        const userPointX = currentUser.x + avatarSize / 2;
        const userPointY = currentUser.y + avatarSize / 2;

        return (
            userPointX >= START_ZONE.x &&
            userPointX <= START_ZONE.x + START_ZONE.width &&
            userPointY >= START_ZONE.y &&
            userPointY <= START_ZONE.y + START_ZONE.height
        );

};
