import { LockFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { PrivateZoneType } from '../../index-types';

interface Props {
  id: number;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  forceHover: boolean;
  isInPrivateZone: boolean;
  privateZone?: PrivateZoneType;
}

function PrivateZone({
  id,
  positionX,
  positionY,
  width,
  height,
  forceHover,
  isInPrivateZone,
  privateZone
}: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);

  useEffect(() => {
    if (isInPrivateZone) {
      setIsHover(false);
    }
  }, [isInPrivateZone]);

  return (
    <div
      className="absolute transition-all duration-500 z-10"
      style={{
        transform: `translate(${positionX}px, ${positionY}px)`,
        visibility: 'visible',
      }}
      onMouseEnter={() => !isInPrivateZone && setIsHover(true)}
      onMouseLeave={() => !isInPrivateZone && setIsHover(false)}
    >
      {privateZone?.password && (<div className='absolute text-[10vh] left-[300px]'>
        <LockFilled style={{
          backgroundColor: '#BCF2F6',
          padding: '25px',
          zIndex: 1,
        }}/>
      </div> )}
      <div
        className={`absolute bg-black ${isHover || forceHover ? 'opacity-20' : 'opacity-0'}`}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          filter: `blur(${isHover || forceHover ? '10px' : '5px'})`,
          transition: 'filter 0.3s, opacity 0.3s',
        }}
      >

      </div>
    </div>
  );
}

export default PrivateZone;
