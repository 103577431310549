import React from 'react';


interface TalkPointProps {
  positionX?: number,
  positionY?: number,
  talkImage?: string,
  talkWidth?: number,
  talkHeight?: number,
}

function talkPoint({ positionX, positionY, talkImage, talkWidth, talkHeight }: TalkPointProps) {

  //done
  return (
    <div
      className={`absolute h-[50px] w-[50px] rounded-full border-4 border-[#efff86] bg-white transition-all duration-500 flex items-center justify-center`}
      style={{
        transform: `translate(${positionX}px, ${positionY}px)`,
        visibility: 'visible',
      }}
    >
      <div className="absolute rounded-full bg-gray-200 opacity-10"
        style={{
          width: `${talkWidth}px`,
          height: `${talkHeight}px`,
        }}
      />
      <img
        src={talkImage}
        alt="People Icon"
        className="h-[70%] w-[70%] object-cover"
      />
    </div>
  );
}

export default talkPoint;
