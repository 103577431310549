import { useState, useEffect } from 'react';

function useWindownSize() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Cập nhật kích thước màn hình khi chiều cao thay đổi
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener khi component bị unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowHeight, windowWidth };
}

export default useWindownSize;
