import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './loading-layer.scss';

const LoadingLayerOffice = (props: { content: any }) => {
  const { content } = props;
  return (
    <div className="flex justify-center  flex-col items-center mx-[4vw] w-[30vw] h-[30vh] opacity-70 z-[50] right-0 fixed bg-black bg-opacity-80 rounded-lg mt-[55px] ">
      <LoadingOutlined style={{ fontSize: '4vw', color: '#fff' }} />
      <p className="mt-5 text-white" style={{fontSize: '1vw'}} >{content}</p>
    </div>
  );
};

export default LoadingLayerOffice;
