// LimitContext.tsx
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import pLimit, { LimitFunction } from 'p-limit';

interface LimitProviderProps {
  concurrency?: number;
  children: ReactNode;
}

const ZoomLimitContext = createContext<LimitFunction | undefined>(undefined);
const MoveHanderLimitContext = createContext<LimitFunction | undefined>(undefined);

export const LimitProvider: React.FC<LimitProviderProps> = ({
  concurrency = 1,
  children,
}) => {
  const zoomLimit = useMemo(() => pLimit(concurrency), [concurrency]);
  const moveHanderLimit = useMemo(() => pLimit(concurrency), [concurrency]);

  return (
    <ZoomLimitContext.Provider value={zoomLimit}>
      <MoveHanderLimitContext.Provider value={moveHanderLimit}>
        {children}
      </MoveHanderLimitContext.Provider>

    </ZoomLimitContext.Provider>
  );
};

export const useZoomLimit = (): LimitFunction => {
  const context = useContext(ZoomLimitContext);
  if (!context) {
    throw new Error('useLimit must be used within a LimitProvider');
  }
  return context;
};

export const useMoveHanderLimit = (): LimitFunction => {
  const context = useContext(MoveHanderLimitContext);
  if (!context) {
    throw new Error('useLimit must be used within a LimitProvider');
  }
  return context;
};
