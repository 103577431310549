import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons/lib/components/Icon";

const CameraSvg = () => {
    return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4377_17)">
        <path d="M11.3571 3.19152L9.64286 4.17857V3.07812L8.67857 4.08594V3.27344L9.64286 2.30078V2.14286C9.64286 1.67009 9.25848 1.28571 8.78571 1.28571H0.857143C0.384375 1.28571 0 1.67009 0 2.14286V9.85714C0 10.3299 0.384375 10.7143 0.857143 10.7143H8.78571C9.25848 10.7143 9.64286 10.3299 9.64286 9.85714V7.82143L11.3571 8.80848C11.6424 8.97321 12 8.76696 12 8.43884V3.5625C12 3.23304 11.6424 3.02679 11.3571 3.19152ZM8.67857 9.75H3.07932L2.13403 10.7143H1.19531L2.16016 9.75H0.964286V2.25H8.67857V9.75ZM11.0357 7.51339L9.64286 6.7125V5.28884L11.0357 4.48661V7.51339ZM1.92857 3.96429H3.42857C3.4875 3.96429 3.53571 3.91607 3.53571 3.85714V3.21429C3.53571 3.15536 3.4875 3.10714 3.42857 3.10714H1.92857C1.86964 3.10714 1.82143 3.15536 1.82143 3.21429V3.85714C1.82143 3.91607 1.86964 3.96429 1.92857 3.96429Z" fill="black"/>
        <line x1="10.8948" y1="0.354515" x2="-0.64741" y2="11.834" stroke="black"/>
        </g>
        <defs>
        <clipPath id="clip0_4377_17">
            <rect width="12" height="12" fill="white"/>
        </clipPath>
        </defs>
    </svg>
    );
};

export const CameraIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={CameraSvg} {...props} />
)